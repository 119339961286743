<template>
    <div class="hourly-trade-returns-widget">
        <div class="col-12 d-flex align-items-center justify-content-end" v-if="!showTable && showChart">
            <b-form-select id="hourly-trade-returns-type-filter" class="mb-2 mr-sm-2 mb-sm-0" @change="requests"></b-form-select>
        </div>
        <div class="col-12 d-flex align-items-center justify-content-end mb-2">
            <b-button variant="light" class="mr-auto" @click="onExport">
                Export
            </b-button>
            <date-range-picker v-model="dateRange" opens="left" @update="requests" class="mr-2">
                <template v-slot:input="picker" style="min-width: 350px;">
                    <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                    <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                </template>
            </date-range-picker>
        </div>
        <highcharts :options="chartOptions" :style="`height: ${height};`" v-if="showChart"></highcharts>
        <div class="col-12 d-flex align-items-center justify-content-end mb-2" v-if="showTable && showChart">
            <b-button variant="light" class="mr-auto" @click="onExport">
                Export
            </b-button>
            <date-range-picker v-model="dateRange" opens="left" @update="requests" class="mr-2" :ranges="dateRanges">
                <template v-slot:input="picker" style="min-width: 350px;">
                    <span>{{ picker.startDate | date('MMM dd, yyyy') }} - {{ picker.endDate | date('MMM dd, yyyy') }}</span>
                    <i class="mdi mdi-calendar-blank" style="padding-left: 7px;"></i>
                </template>
            </date-range-picker>
        </div>
        <template v-if="showTable">
            <b-table id="audit-table" :items="tableOptions.items" :fields="tableOptions.columns" :next-class="'nextPageAudit'" responsive class="text-center"
                show-empty :per-page="tableOptions.rowAmount" :current-page="tableOptions.currentPage" >
                <template slot="empty">
                    <span id="noresults">Loading Data...</span>
                </template>
            </b-table>
            <div class="row mt-3">
                <div class="col-12 d-flex align-items-center justify-content-start">
                    <b-pagination v-model="tableOptions.currentPage" v-on:change="requests" :total-rows="tableOptions.totalRows" :per-page="tableOptions.rowAmount" aria-controls="audit-table">

                    </b-pagination>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { compareAsc, subBusinessDays, isToday, startOfMonth, endOfMonth, format, startOfWeek, endOfWeek, addMonths, startOfQuarter, endOfQuarter, addQuarters, startOfYear, endOfYear, addYears } from 'date-fns';
import { numberFormat } from 'highcharts';
import DateRangePicker from 'vue-daterange-picker-light';
import 'vue-daterange-picker-light/dist/vue-daterange-picker-light.css';
import { getColor } from '@/lib/ColorLookup';
import { exportCsv } from '@/lib/Exporting';
import { map, clone } from 'lodash';

export default {
    name: 'audit',
    data() {
        let date = new Date();

        return {
            dateRange: {
                startDate: new Date(`2020-${new Date(Date.now()).getMonth()+1}-01`),
                endDate: endOfMonth(date)
            },
            timePeriod: 'Hourly',
            filterOptions: {
                timePeriod: [
                    {
                        value: 'Monthly',
                        text: 'Monthly'
                    },
                    {
                        value: 'Weekly',
                        text: 'Weekly'
                    },
                    {
                        value: 'Daily',
                        text: 'Daily'
                    },
                    {
                        value: 'Hourly',
                        text: 'Hourly'
                    }
                ]
            },
            chartOptions: {
                chart: {
                    type: 'line',
                    zoomType: 'x'
                },
                title: {
                    text: ''
                },
                xAxis: {
                    type: 'datetime'
                },
                yAxis: {
                    title: {
                        text: 'Return'
                    },
                    labels: {
                        overflow: 'justify',
                        formatter: function() {
                            return this.value + '%';
                        }
                    }
                },
                tooltip: {
                    valueSuffix: '%',
                    valueDecimals: 2
                },
                legend: {
                    enabled: true
                },
                credits: {
                    enabled: false
                },
                plotOptions: {
                    line: {
                        marker: {
                            symbol: 'circle'
                        }
                    }
                },
                rangeSelector: {
                    enabled: true,
                    verticalAlign: 'top',
                    x: 0,
                    y: 0
                },
                series: []
            },
            tableOptions: {
                columns: [],
                items: [],
                currentPage: 1,
                rowAmount: 100,
                totalRows: 0
            },
            dateRanges: {
                'This Week': [startOfWeek(date), endOfWeek(date)],
                'This Month': [startOfMonth(date), endOfMonth(date)],
                'Last Month': [startOfMonth(addMonths(date, -1)), endOfMonth(addMonths(date, -1))],
                'This Quarter': [startOfQuarter(date), endOfQuarter(date)],
                'Last Quarter': [startOfQuarter(addQuarters(date, -1)), endOfQuarter(addQuarters(date, -1))],
                'This Year': [startOfYear(date), endOfYear(date)],
                'Last Year': [startOfYear(addYears(date, -1)), endOfYear(addYears(date, -1))],
                'All Time': [new Date('2020-01-01'), endOfYear(date)]
            }
        };
    },
    props: {
        account: {
            type: String,
            default: ''
        },
        showTable: {
            type: Boolean,
            default: true
        },
        showChart: {
            type: Boolean,
            default: true
        },
        height: {
            type: String,
            default: '400px'
        }
    },
    components: {
        DateRangePicker
    },
    mounted() {
        this.requests(true);
    },
    beforeDestroy() {
        this.$ws.off('reconnected', this.requests);
        this.$ws.off('audit', this.Audit);
    },
    methods: {
        requests(initial = false) {
            if(!this.hasSetupListeners) {
                this.$ws.on('reconnected', this.requests);
                this.$ws.on('audit', this.Audit);
                this.hasSetupListeners = true;
            }

            let account = this.account;
            if(!account) {
                account = this.$store.state.activeAccount;
            }
            if(document.getElementById("noresults")){document.getElementById("noresults").innerHTML = "Loading data...";}
            this.tableOptions.columns = [];
            this.tableOptions.items = [];

            this.$ws.send({
                sessionID: 'NotImplemented',
                accountName: account,
                request: 'Audit',
                args: {
                    timePeriod: this.timePeriod,
                    startDate: format(this.dateRange.startDate, 'yyyy-MM-dd'),
                    endDate: format(this.dateRange.endDate, 'yyyy-MM-dd'),
                    rowAmount: this.tableOptions.rowAmount-1,
                    rowOffset: this.tableOptions.currentPage-1
                }
            });
        },
        Audit(event) {
            console.log(event.response)
            if(document.getElementById("noresults")){document.getElementById("noresults").innerHTML = "No results found";}
            if(!event.response[1][0]){return}
            event.response[1] = JSON.parse(event.response[1])
            let columns = [];
            let rows = this.tableOptions.items.slice();
            let offset = (this.tableOptions.currentPage-1)*this.tableOptions.rowAmount;


            this.tableOptions.totalRows = Math.ceil(event.response[0]/this.tableOptions.rowAmount);
            
            let len = Object.keys(event.response[1][0]).length;
            for(let i=0;i<len;i++){
                let name = Object.keys(event.response[1][0])[i]
                name = name == "Record" ? "Entry": name;
                columns.push({
                    key:name,
                    label:name,
                    sortable: true
                 })
            }
            len = event.response[1].length;
            for(let i=0;i<len;i++){
                let time = event.response[1][i][Object.keys(event.response[1][0])[0]].replace("T", " ");
                //time = time.indexOf(".")? time.slice(0, time.indexOf(".")): time; //cuts off decimals from seconds
                let entry = event.response[1][i][Object.keys(event.response[1][0])[5]];
                try {
                    entry = JSON.parse(event.response[1][i][Object.keys(event.response[1][0])[5]]);
                } catch(e) {
                    entry = event.response[1][i][Object.keys(event.response[1][0])[5]];
                }

                rows[++offset] ={
                    Timestamp:time,
                    Component: event.response[1][i][Object.keys(event.response[1][0])[1]],
                    Type: event.response[1][i][Object.keys(event.response[1][0])[2]],
                    Symbol: event.response[1][i][Object.keys(event.response[1][0])[3]],
                    TradeNumber: event.response[1][i][Object.keys(event.response[1][0])[4]],
                    Entry: entry,
            }

            }

            
            this.tableOptions.columns = columns;
            this.tableOptions.items = rows;
        
        },
        onExport() {
            let headers = {};

            for(let column of this.tableOptions.columns) {
                headers[column.key] = column.label;
            }

            let items = map(this.tableOptions.items, clone);
            
            for(let item of items) {
                //escape character to allow commas
                item.timestamp = "\"" + item.timestamp + "\""; 
            }

            exportCsv(headers, items, 'Audit');
        },
    }
}
</script>